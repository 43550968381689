import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Tên miền <code>hoantien.store</code> đã hết hạn. Vui lòng try cập bằng tên miền mới
        </p>
        <a
          className="App-link"
          href="https://hoantien.top"
          // target="_blank"
          rel="noopener noreferrer"
        >
          https://hoantien.top
        </a>
      </header>
    </div>
  );
}

export default App;
